import { CreateAuthHeader } from "../helpers/authentication-helper";
import { ISales, IServiceLetter } from "../models";
import { IService } from "./ICommonServices";

export class SalesServiceProvider implements IService<ISales>
{
    private controller = "api/sales";

    public async GetAll(): Promise<ISales[]> 
    {
        let result: ISales[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            return result;
        }

        return await response.json() as ISales[];
    }

    public async Get(id: number): Promise<ISales> 
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        if(!response.ok)
        {
            throw new Error("Serviceletter not found");
        }

        return await response.json() as ISales;
    }

    public async Create(item: ISales) : Promise<string>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Update(item: ISales) : Promise<string>
    {
        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Delete(id: number) : Promise<string>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        return await response.text();
    }   
}
