import React from 'react';
import { initializeIcons } from '@fluentui/react';
import { AppLayout } from './components/AppLayout/AppLayout';
import { Stakeholders } from './components/Stakeholders/Stakeholders';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import UserManagement from './components/UserManagement/UserManagement';
import ChangePassword from './pages/ChangePassword';
import { ApplicationContext } from './contexts/ApplicationContext';
import { CommonServices } from './services/ICommonServices';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import styles from './App.module.scss';
import { ErrorPage } from './components/AppLayout/ErrorPage';
import { AuthenticationProvider } from './authentication/AuthenticationProvider';
import Dashboard from './components/Dashboard/Dashboard';
import RequireAuth from './authentication/RequireAuth';
import Sales from './pages/Sales';
import { Banks, Codes, Company, Misc, Nets, Rates, TaxGroups, Turbines, Users } from './components/Settings';
import Export, { ServiceLetter } from './pages/ServiceLetter';
import Payout from './pages/Payout';
import { useAuthenticationContext } from './hooks/useAuthenticationContext';
import Header, { TopNavigation } from './components/AppLayout/TopNavigation';
import Settings from './components/Settings/Settings';
import Login from './components/Login/Login';
import ShareControl from './pages/ShareControl';
import ProductionOverview from './pages/ProductionOverview';

export const App: React.FunctionComponent<{}> = (props: React.PropsWithChildren<{}>) => { 
    React.useEffect(() => { initializeIcons(); }, []);
    
    const router = createBrowserRouter([
    {
        path: "/",
        element: <RequireAuth redirectTo="/Login"><TopNavigation /><AppLayout /></RequireAuth>,
        errorElement: <ErrorPage />,          
        //loader: rootLoader,
        children: [
            {
                path: "/",
                element: <Dashboard />,
            },
            {
                path: "interessenter",
                element: <Stakeholders />,
            },
            {
                path: "køb-salg",
                element: <Sales />,
            },            
            {
                path: "udbetalinger",
                element: <Payout />,
            },
            {
                path: "servicebreve",
                element: <ServiceLetter />,
            },
            {
                path: "anpartskontrol",
                element: <ShareControl />,
            },
            {
                path: "produktionsoversigt",
                element: <ProductionOverview />,
            },
            {
                path: "indstillinger",
                element: <Settings />,
            },
            {
                path: "indstillinger/firma",
                element: <Company />,
            },
            {
                path: "indstillinger/banker",
                element: <Banks />,
            },
            {
                path: "indstillinger/nets",
                element: <Nets />,
            },
            {
                path: "indstillinger/skattegrupper",
                element: <TaxGroups />,
            },
            {
                path: "indstillinger/ajourkoder",
                element: <Codes />,
            },
            {
                path: "indstillinger/satser",
                element: <Rates />,
            },
            {
                path: "indstillinger/vindmøller",
                element: <Turbines />,
            },
            {
                path: "indstillinger/brugere",
                element: <Users />,
            },
            {
                path: "indstillinger/diverse",
                element: <Misc />,
            },
            {
                path: "/skift-adgangskode",
                element: <ChangePassword />,
            },
            {
                path: "/brugeradministration",
                element: <UserManagement />,
            },
        ],
    },
    {
        path: "/Login",
        errorElement: <ErrorPage />,   
        element: <Login />
    }
    ]);

    return (
        <div className={styles.app}>
            <div className={styles.header}>
                <div className={styles.logo}><a href={`/`}><img src="images/logo.png" alt="Uhrevind"/><h1>Uhrevind</h1></a></div>                
            </div>
            <FluentProvider theme={teamsLightTheme}>
                <AuthenticationProvider>
                    <ApplicationContext.Provider value={{Services: new CommonServices(), ShareCount: 5245 /* ToDo: Get from backend */}}>
                        <React.StrictMode>
                            <RouterProvider router={router} />
                        </React.StrictMode>
                    </ApplicationContext.Provider>
                </AuthenticationProvider>
            </FluentProvider>
      </div>
    );
}
  
export default App;