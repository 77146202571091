import * as React from "react";

interface IUserManagementProps
{
    
}

export const UserManagement : React.FunctionComponent<IUserManagementProps> = (props: React.PropsWithChildren<IUserManagementProps>) => {

        return <div><h3>Brugeradministration</h3></div>
}

export default UserManagement;