import { IContextualMenuProps, CommandButton, CompoundButton, Dialog, DialogContent, DialogType, DialogFooter, ICommandBarItemProps, CommandBar } from '@fluentui/react';
import React from 'react';
import styles from './AppLayout.module.scss';
import { Role } from '../../helpers/enums';
import { Text, getTheme } from "office-ui-fabric-react";
import { Sidebar, SidebarButton } from "@uifabric/experiments";
import { Link, NavLink, Navigate, NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import { Persona } from '@fluentui/react-components';
interface ITopNavigationProps
{

}

const DefaultCommandBarItem: (name: string, iconName: string) => ICommandBarItemProps = (name: string, iconName: string) => ({
  key: name,
  name: name,
  iconProps: { iconName: iconName },
});

export const TopNavigation : React.FunctionComponent<ITopNavigationProps> = (props: React.PropsWithChildren<ITopNavigationProps>) => {  
  const navigate: NavigateFunction = useNavigate();  

  const Commands: ICommandBarItemProps[] = [
    { ...DefaultCommandBarItem("Interessenter", "People"), onClick: () => { navigate("/interessenter"); } },
    { ...DefaultCommandBarItem("Køb & salg", "RealEstate"), onClick: () => { navigate("/køb-salg"); } },
    { ...DefaultCommandBarItem("Udbetalinger", "Money"), onClick: () => { navigate("/udbetalinger"); } },
    { ...DefaultCommandBarItem("Servicebreve", "Certificate"), onClick: () => { navigate("/servicebreve"); } },
    { ...DefaultCommandBarItem("Anpartskontrol", "ActivateOrders"), onClick: () => { navigate("/anpartskontrol"); } },
    { ...DefaultCommandBarItem("Produktionsoversigt", "AreaChart"), onClick: () => { navigate("/produktionsoversigt"); } },
    //{ ...DefaultCommandBarItem("Eksport", "ExcelLogo"), onClick: () => { navigate("/eksport"); } },
  ];
  
  const CreateFarMenuItems = [
    { ...DefaultCommandBarItem("Indstillinger", "Settings"), onClick: () => { navigate("/indstillinger"); } },
  ];
  
  return (         
            <div className={styles.TopNavigation}>            
              <CommandBar items={Commands} farItems={CreateFarMenuItems} />
            </div>
      );
  }

  export default TopNavigation;
