import { CompoundButton, Stack } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface ISettingsProps
{

}

export const Settings: React.FunctionComponent<ISettingsProps> = (props: React.PropsWithChildren<ISettingsProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    
    return <div>
                <h3>Indstillinger</h3>
                <h5>Generelt</h5>  
                <div style={{display:"grid", gap:"5px",gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))"}}>
                    <CompoundButton text='Banker' secondaryText='Her kan du oprette og ændre alle banker' onClick={() => { navigate("banker"); }}/>
                    <CompoundButton text='Nets' secondaryText='Her kan du...'/>
                    <CompoundButton text='Skattegrupper' secondaryText='Her kan du...'/>
                    <CompoundButton text='Ajourføringskoder' secondaryText='Her kan du...'/>
                    <CompoundButton text='Satser' secondaryText='Her kan du...'/>                    
                    <CompoundButton text='Diverse' secondaryText='Her kan du...'/>
                </div>   
                <h5>Stamdataoplysninger</h5>  
                <div style={{display:"grid", gap:"5px",gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))"}}>
                    <CompoundButton text='Vindmøllelaug' secondaryText='Her kan du vedligeholde laugets stamdata' onClick={() => { navigate("firma"); }}/>
                    <CompoundButton text='Møller' secondaryText='Her kan du vedligeholde møllerne'/>
                    <CompoundButton text='Brugere' secondaryText='Her kan du...'/>
                </div>           
           </div>
}

export default Settings;