import React from 'react';

interface ICompanyProps
{

}

export const Company: React.FunctionComponent<ICompanyProps> = (props: React.PropsWithChildren<ICompanyProps>) => {
    return <div><h3>Firma</h3></div>
}

export default Company;