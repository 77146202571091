import { createContext } from "react";
import { IUser } from '../models';
import { IGuild } from "../models/IGuild";

 interface AuthenticationContext {
  user: IUser | null;
  login: (user: IUser) => void;
  logout: () => void;
  isCheckingAuth: boolean;
  guild: IGuild | null;
  setCurrentGuild: (guild: IGuild | null) => void;
  isUserInRole(role: string): boolean;
}

export const AuthenticationContext = createContext<AuthenticationContext>({
  user: null,
  login: () => {},
  logout: () => {},
  isCheckingAuth: true,
  guild: null,
  setCurrentGuild: () => {},
  isUserInRole: () => false,
});

