import React from 'react';

interface IUsersProps
{

}

export const Users: React.FunctionComponent<IUsersProps> = (props: React.PropsWithChildren<IUsersProps>) => {
    return <div><h3>Brugere</h3></div>
}

export default Users;