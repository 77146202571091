import React from 'react';
import { useAuthenticationContext } from '../hooks/useAuthenticationContext';
import { Navigate } from 'react-router-dom';

export interface IRequireAuthProps {
    redirectTo: string;
}

export const RequireAuth: React.FunctionComponent<IRequireAuthProps> = (props: React.PropsWithChildren<IRequireAuthProps>) => {
    const { user, isCheckingAuth } = useAuthenticationContext();

    if(isCheckingAuth) return <></>

    return user ? <>{props.children}</> : <Navigate to={props.redirectTo} />;
}

export default RequireAuth;