import React from 'react';
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import GuildPicker from './GuildPicker/GuildPicker';
import { IGuild } from '../../models/IGuild';
import appstyles from './../../App.module.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDashboardProps
{

}

export const Dashboard: React.FunctionComponent<IDashboardProps> = (props: React.PropsWithChildren<IDashboardProps>) => {    
    const [showGuildPicker, setShowGuildPicker] = React.useState<boolean>(false);
    const { user, guild, setCurrentGuild } = useAuthenticationContext();

    React.useEffect(() => {
        if(user != null && user.guilds.length > 1 && guild == null)
        {
          setShowGuildPicker(true);
        } 
      }, []);
    
      // const data = {
      //   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      //   datasets: [
      //     {
      //       label: '# of Votes',
      //       data: [12, 19, 3, 5, 2, 3],
      //       backgroundColor: [
      //         'rgba(255, 99, 132, 0.2)',
      //         'rgba(54, 162, 235, 0.2)',
      //         'rgba(255, 206, 86, 0.2)',
      //         'rgba(75, 192, 192, 0.2)',
      //         'rgba(153, 102, 255, 0.2)',
      //         'rgba(255, 159, 64, 0.2)',
      //       ],
      //       borderColor: [
      //         'rgba(255, 99, 132, 1)',
      //         'rgba(54, 162, 235, 1)',
      //         'rgba(255, 206, 86, 1)',
      //         'rgba(75, 192, 192, 1)',
      //         'rgba(153, 102, 255, 1)',
      //         'rgba(255, 159, 64, 1)',
      //       ],
      //       borderWidth: 1,
      //     },
      //   ],
      // };

      return <div className={appstyles.contentContainer}>            
      <div className={appstyles.heading}><h4>Velkommen, {user?.name} 😎</h4></div>
      <div>Dette system er designet til at hjælpe dig med at administrere dit vindmøllelaug effektivt. Her kan du:
        <ul>
          <li>Vedligeholde interessenter: Registrer og opdater information om laugsmedlemmer, investorer og andre interessenter. Hold styr på deres kontaktoplysninger og roller.</li>
          <li>Udføre udbetalinger: Foretag udbetalinger til laugsmedlemmer baseret på vindmøllernes produktion og økonomiske resultater.</li>
          <li>Ajourføre produktionsoversigten: Få løbende indtastet produktionstal så de er klar til brevfletning</li>
        </ul>
      Vi er her for at gøre din administration mere strømlinet og effektiv. Hvis du har spørgsmål eller brug for hjælp, er du velkommen til at kontakte os. 🌬️💼</div>
      {/* <div style={{width:"40vw", margin:"auto"}} ><Doughnut data={data} {...props} /></div> */}
      {showGuildPicker && <GuildPicker onSelect={(guild: IGuild | undefined) => { setCurrentGuild(guild as IGuild); setShowGuildPicker(false); } } />}
      </div>
}

export default Dashboard;