import React from 'react';

interface IRatesProps
{

}

export const Rates: React.FunctionComponent<IRatesProps> = (props: React.PropsWithChildren<IRatesProps>) => {
    return <div><h3>Satser</h3></div>
}

export default Rates;