import { useState } from 'react';

export default function UseObject<T>(InitValue: T) {
    const [value, setValue] = useState<T>(InitValue);
    const [changes, setChanges] = useState<Partial<T>>({});

    const update: (change: Partial<T>) => void = (change) => {
        setChanges(c => ({ ...c, ...change }));
    }

    return {
        value: { ...value, ...changes },
        updateValue: update,
        setValue: (val: T) => { setValue(val); setChanges({}) },
        changedValues: changes,
        hasChanges: Object.keys(changes).length > 0
    }
}