import React from 'react';

interface ITaxGroupsProps
{

}

export const TaxGroups: React.FunctionComponent<ITaxGroupsProps> = (props: React.PropsWithChildren<ITaxGroupsProps>) => {
    return <div><h3>Skattegrupper</h3></div>
}

export default TaxGroups;