import React from 'react';
import { useRouteError } from 'react-router-dom';
import appstyles from './../../App.module.scss';

export const ErrorPage : React.FunctionComponent<{}> = (props: React.PropsWithChildren<{}>) =>
{
    const error : any = useRouteError();
    console.error(error);
    return (
        <div className={appstyles.contentContainer} style={{textAlign:"center"}}>
        <h1>Oops! 😢</h1>
        <p>Der er desværre sket en uventet fejl. Prøv igen senere.</p>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
        </div>
    );
}