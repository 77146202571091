import { ChoiceGroup, Dialog, DialogType, IChoiceGroupOption, PrimaryButton } from '@fluentui/react';
import React from 'react';
import commonStyles from '../../styles/Common.module.scss';
import { IGuild } from '../../../models/IGuild';
import App from '../../../App';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { useAuthenticationContext } from '../../../hooks/useAuthenticationContext';
import { useApplicationContext } from '../../../hooks/useApplicationContext';

interface IGuildPickerProps
{
    onSelect(guild: IGuild | undefined): void;
}

export const GuildPicker: React.FunctionComponent<IGuildPickerProps> = (props: React.PropsWithChildren<IGuildPickerProps>) => {
    //const { Services } = React.useContext(ApplicationContext);
    const { Services } = useApplicationContext();
    const { user } = useAuthenticationContext();
    //const { user } = useAuth();
    const [guild, setGuild] = React.useState<IGuild>();
    
    if(user == null) return <></>;
    return (<Dialog  hidden={false}
        //onDismiss={() => onDismiss(false)}
        dialogContentProps={{
            type: DialogType.normal,
            title: `Vælg vindmøllelaug`,
        }}
    >
        <div>
            <ChoiceGroup options={user.guilds.map((val, i, a) => { return { key: val.id, text: val.name } as IChoiceGroupOption })} required={true} onChange={(ev, option?: IChoiceGroupOption) => { 
                if(option == undefined) 
                {
                    setGuild(undefined);
                } else 
                {
                    setGuild(user.guilds.filter(x => x.id == option.key + "")[0]);
                }            
            }} />            
                
        </div>
        <PrimaryButton disabled={guild == undefined} text="Fortsæt" onClick={()=> { Services?.Master.SetGuild(guild).then(() => { props.onSelect(guild); }) }} />
        
    </Dialog>);    
}

export default GuildPicker;