import { TextField, CompoundButton, DatePicker, DefaultButton, DetailsListLayoutMode, DetailsRow, Dropdown, IColumn, ICommandBarItemProps, IDetailsListProps, IDetailsRowStyles, Panel, PanelType, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, mergeStyles, Label, MessageBar, MessageBarType, IDropdownOption } from '@fluentui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import ListComponent from '../components/ListComponent';
import { FormMode } from '../helpers/enums';
import { ApplicationContext } from '../contexts/ApplicationContext';
import { IBank, IPayout } from '../models';
import useObject from '../hooks/useObject';
import { Save20Regular, CalendarCancel20Regular, Delete20Regular } from '@fluentui/react-icons';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import CustomTextField from '../components/FluentUI9/TextField';
import styles from '../App.module.scss';
import StepWizard from "react-step-wizard";
import { format } from "date-fns";
import { DatePicker_onFormatDate } from '../helpers/DateHelper';


interface IPayoutProps
{

}

export const Payout: React.FunctionComponent<IPayoutProps> = (props: React.PropsWithChildren<IPayoutProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { Services } = React.useContext(ApplicationContext);
    const [items, setItems] = React.useState<IPayout[]>([]);  
    const [selected, setSelected] = React.useState<IPayout | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue } = useObject<IPayout>({} as IPayout);
    
    const [formMode, setFormMode] = React.useState<FormMode>(FormMode.New);
    
    const COLUMNS = [ //ToDo
        {
          key: 'period',
          name: 'Periode',
          fieldName: 'period',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        },
        {
          key: 'amount',
          name: 'Udbetaling pr. andel',
          fieldName: 'amount',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false
        },
        {
          key: 'extra',
          name: 'Ekstra pr. andel',
          fieldName: 'extra',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false
        },
        {
            key: 'start',
            name: 'Startdato',
            fieldName: 'start',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IPayout) => { return <span>{format(item.start, "dd-MM-yyyy")}</span> }
          },
          {
            key: 'end',
            name: 'Slutdato',
            fieldName: 'end',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            onRender: (item: IPayout) => { return <span>{format(item.start, "dd-MM-yyyy")}</span> }
          }
      ] as IColumn[];

      React.useEffect(() => {    
        Fetch();
      }, []);

      React.useEffect(() => {
        setValue(selected as IPayout);
      }, [selected]);

const Fetch = async () => {
    Services?.Payout.GetAll().then((result: IPayout[]) => {          
        setIsLoading(true);
        setItems(result);
        setIsLoading(false);
      }); 
}



const AddOrUpdate = async () =>
{
    let result : string | undefined = undefined;
    
    switch(formMode)
    {
        case FormMode.New:
            result = await Services?.Payout.Create(entity);
            break;
        case FormMode.Edit:
            result = await Services?.Payout.Update(entity);
            break;
    }
    
    if(result === "OK")
    {
        // const objIndex  = items.findIndex(x => x.regNo == entity?.regNo);
        // items[objIndex] = entity; 

        Reset();
        Fetch();
    } else
    {
        alert(result);
    }
}

const Reset = () => {
    setSelected(undefined);
    setShowPanel(false);
}
      const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
          return <div title="Klik for at åbne" style={{cursor: "pointer"}} onClick={() => { setFormMode(FormMode.Edit); setSelected(props.item); setShowPanel(true); }}><DetailsRow {...props} styles={customStyles} /></div>
        }
        return null;
      }

      const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Ny udbetaling',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setFormMode(FormMode.New); setShowPanel(true);
             },
            disabled: selected != undefined
        }
        ];

      const CreateFarMenuItems = [
        {        
          key:"search",
          onRender:() => <SearchBox 
            placeholder="Søg efter ..." 
            className={styles.searchBox} 
            onSearch={(newValue) => { /*this.onSearch(newValue);*/ } } 
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { /*onSearch(newValue);*/ }}
            onClear={ev => { /*onSearch("");*/ }}
            />
        }
      ];
      
    return <div>
                <ListComponent commands={Commands} farItems={CreateFarMenuItems} title={'Udbetalinger'}>
                <ShimmeredDetailsList
                    onShouldVirtualize={() => false }
                    items={items}
                    columns={COLUMNS}
                    selectionMode={SelectionMode.none}
                    //onRenderRow={_onRenderRow?.bind(this)}           
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    enableShimmer={isLoading}
                    setKey="set"
                    />
                </ListComponent>
                {showPanel && 
                    <Panel
                    headerText={formMode === FormMode.New ? "Ny udbetaling" : "Rediger"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) Reset(); }}              
                    closeButtonAriaLabel="Luk panel"
                    // onRenderFooterContent={() => {
                    // return <>
                    //     <PrimaryButton appearance="primary" icon={<Save20Regular />} onClick={() => AddOrUpdate()} label={formMode === FormMode.New ? "Opret" : "Gem ændringer"} />
                    //     <PrimaryButton appearance="normal" icon={<CalendarCancel20Regular />}  onClick={() => Reset() } label="Annuller" />                  
                    //     {formMode === FormMode.Edit && <PrimaryButton appearance="delete" icon={<Delete20Regular />}  onClick={() => Delete()} label="Slet" />}
                    // </>
                    // }}
                    // type={PanelType.custom} customWidth='1000px' >
                    type={PanelType.medium}>
                            <FluentProvider theme={teamsLightTheme}>
                            <div>                            
                            {/* <ProgressBar
        percent={50}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        <Step>
    {({ accomplished, index }) => (
      <div className={mergeStyles(styles.indexedStep, accomplished ? styles.accomplished : null)}>
        {index + 1}
      </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div className={mergeStyles(styles.indexedStep, accomplished ? styles.accomplished : null)}>
        {index + 1}
      </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div className={mergeStyles(styles.indexedStep, accomplished ? styles.accomplished : null)}>
        {index + 1}
      </div>
    )}
  </Step>
                </ProgressBar>
                 */}
                 <Wizard onDismiss={() => { setShowPanel(false); }} recentPayouts={items.slice(-5).reverse()} />
                            </div>
                            </FluentProvider>
                    </Panel>
                }        
           </div>
}
interface IWizardProps
{
  recentPayouts: IPayout[];
  onDismiss(): void;
}
const Wizard = (props: IWizardProps) => {
    const [state, updateState] = useState({
        form: {},
        transitions: {
            //enterRight: `${transitions.animated} ${transitions.enterRight}`,
        },
        SW: null
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        // console.log(stats);
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    return (
        <StepWizard
                            onStepChange={onStepChange}                            
                            isHashEnabled
                            transitions={state.transitions} // comment out for default transitions
                            nav={<Nav />}
                            instance={setInstance}
                        >
                            <First hashKey={'step1'} recentPayouts={props.recentPayouts} update={updateForm} />
                            <Second hashKey={'step2'} />
                            <Third hashKey={'step3'} model={state.form} />
                            <Last hashKey={'step4'} finish={() => { props.onDismiss() }} />
                        </StepWizard>
    );
};

const Nav = (props) => {
    const dots = [] as any[];
    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;
        dots.push(
            <span
                key={`step-${i}`}
                className={`${styles.dot} ${isActive ? styles.active : ''}`}
                //onClick={() => props.goToStep(i)}
            >&bull;</span>
        );
    }

    return (
        <div className={styles.nav}>{dots}</div>
    );
};

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const Stats = ({
    currentStep,
    firstStep,
    goToStep,
    lastStep,
    nextStep,
    previousStep,
    totalSteps,
    step,
}) => (
    <div style={{marginTop:"10px"}}>
        { step > 1 &&            
            <DefaultButton text="Tilbage" style={{marginRight:"10px"}} onClick={previousStep} />
        }
        { step < totalSteps ?            
            <PrimaryButton text="Næste" onClick={nextStep} />
            :
            <PrimaryButton text="Gem og afslut" onClick={nextStep} />
        }
    </div>
);

/** Steps */

const First = props => {
  const { ShareCount } = React.useContext(ApplicationContext);
  const { value: entity, updateValue: updateEntity, setValue } = useObject<IPayout>({amount:0, extra: 0} as IPayout);
  
    return (
        <div>
            <h6>1. Vælg periode og sats pr. andel</h6>
                        
            <Dropdown label='Start fra tidligere udbetaling' onChange={(ev, option) => { setValue(option?.data) }} options={props.recentPayouts.map((val, i, a) => { return { key: val.period, text: val.period, data: val } as IDropdownOption; })}/>
            <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:"10px"}}>
            <TextField label={'Navn på periode'} value={entity?.period} placeholder='Eks. 2024_1' onChange={(ev, newValue: string | undefined ) => { updateEntity({period: newValue}) }} />
            <TextField label={'Sats pr. andel'} value={entity?.amount + ""} prefix='kr.' onChange={(ev, newValue: string | undefined ) => { updateEntity({amount: Number.parseFloat(newValue + "")}) }} />
            <TextField label={'Ekstra pr. andel'} value={entity?.extra + ""} prefix='kr.' onChange={(ev, newValue: string | undefined ) => { updateEntity({extra: Number.parseFloat(newValue + "")}) }} />
            <TextField label={"1.123 interessenter - i alt:"} disabled styles={{fieldGroup: { border:"1px solid rgb(96, 94, 92) !important" }}} value={(ShareCount * (entity.amount+entity.extra)) + ""} prefix='kr.' />                        
            <DatePicker label='Periode - start' value={entity.start} formatDate={DatePicker_onFormatDate} onSelectDate={(date) => { updateEntity({start: date as Date}); }} />            
            <DatePicker label='Periode - slut'  value={entity.end} formatDate={DatePicker_onFormatDate} onSelectDate={(date) => { updateEntity({end: date as Date}); }} />                                    
            </div>
            
            <div style={{display:"grid", gridTemplateColumns:"auto auto", gap:"10px"}}>
            </div>
            <div>
              <DatePicker label='Dispositionsdato'  value={entity.dispositionDate} formatDate={DatePicker_onFormatDate} onSelectDate={(date) => { updateEntity({dispositionDate: date as Date}); props.update("dispositionDate", date); }} />            
            </div>
            <Stats step={1} {...props} />
        </div>
    );
};

const Second = props => {
    return (
        <div>
            <h6>2. Oplysninger til udbetalingsfil (PBS)</h6>
            <ShimmeredDetailsList
                    onShouldVirtualize={() => false }
                    items={[{personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                    {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"},
                {personId: 1806, name: "Jonas Bjerke Hansen", bank: "7621", bankAccount: "10001548", amount: "25.000"}]} //ToDo                    
                    columns={[ {
                        key: 'personId',
                        name: 'Person ID',
                        fieldName: 'personId',
                        minWidth: 75,
                        maxWidth: 75,
                        isResizable: false
                      }, {
                        key: 'name',
                        name: 'Navn',
                        fieldName: 'name',
                        minWidth: 150,
                        maxWidth: 400,
                        isResizable: true
                      }, {
                        key: 'bank',
                        name: 'Reg. nr.',
                        fieldName: 'bank',
                        minWidth: 75,
                        maxWidth: 75,
                        isResizable: false
                      }, {
                        key: 'bankAccount',
                        name: 'Konto nr.',
                        fieldName: 'bankAccount',
                        minWidth: 100,
                        maxWidth: 100,
                        isResizable: false
                      }, {
                        key: 'amount',
                        name: 'Beløb',
                        fieldName: 'amount',
                        minWidth: 75,
                        maxWidth: 75,
                        isResizable: false
                      },]}
                    selectionMode={SelectionMode.none}                    
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    enableShimmer={false} //ToDo
                    setKey="setKey"
                    />
            <Stats step={2} {...props} /*previousStep={validate}*/ />
        </div>
    );
};

const Third = props => {
    function saveFile(url, filename) {
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "file-name";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    const validate = () => {
        const file = new Blob(["PBS file indhold her"], { type: "text/plain" });
        const url = window.URL.createObjectURL(file);
        saveFile(url, `PB${new Date().getDate()}.pbs`);
        window.URL.revokeObjectURL(url);

        props.nextStep();
    };

    return (
        <div>
            <h6>3. Forhåndsvisning af PBS fil</h6>
            <textarea style={{width:"100%", minHeight:"500px"}} readOnly>TEKST INDHOLD HER</textarea>  
            <MessageBar messageBarType={MessageBarType.info}>
              PBS-filen downloades automatisk når du fortsætter. {props.model.dispositionDate && <>Dispositionsdatoen er <b>{format(props.model.dispositionDate, "dd. MMMM yyyy")}</b></> }
              </MessageBar>
            <Stats step={2} {...props} nextStep={validate}/>
        </div>
    );
};

// const Progress = (props) => {
//     const [state, updateState] = useState({
//         isActiveClass: '',
//         timeout: null,
//     });

//     useEffect(() => {
//         // const { timeout } = state;

//         // if (props.isActive && !timeout) {
//         //     updateState({
//         //         isActiveClass: styles.loaded,
//         //         timeout: setTimeout(() => {
//         //             props.nextStep();
//         //         }, 3000),
//         //     });
//         // } else if (!props.isActive && timeout) {
//         //     clearTimeout(timeout);
//         //     updateState({
//         //         isActiveClass: '',
//         //         timeout: null,
//         //     });
//         // }
//     });

//     return (
//         <div className={styles['progress-wrapper']}>
//             <p className='text-center'>Automated Progress...</p>
//             <div className={`${styles.progress} ${state.isActiveClass}`}>
//                 <div className={`${styles['progress-bar']} progress-bar-striped`} />
//             </div>
//         </div>
//     );
// };

const Last = (props) => {
    function saveFile(url, filename) {
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "file-name";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      const validate = () => {
        const file = new Blob(["Udtræk til Excel her"], { type: "text/plain" });
        const url = window.URL.createObjectURL(file);
        saveFile(url, `KundeData.txt`);
        window.URL.revokeObjectURL(url);

        props.finish();
    };

    return (
        <div>
            <h6>4. Eksport fil til brevfletning</h6>
            <MessageBar messageBarType={MessageBarType.info}>
            Filen til brevfletning er nu klar og downloades automatisk når du afslutter.
              </MessageBar>            
            <Stats step={4} {...props} nextStep={validate} />
        </div>
    );
};

export default Payout;