import React from 'react';

interface ITurbinesProps
{

}

export const Turbines: React.FunctionComponent<ITurbinesProps> = (props: React.PropsWithChildren<ITurbinesProps>) => {
    return <div><h3>Vindmøller</h3></div>
}

export default Turbines;