import { IContextualMenuProps, CommandButton, CompoundButton, Dialog, DialogContent, DialogType, DialogFooter, CommandBar } from '@fluentui/react';
import React from 'react';
import styles from './AppLayout.module.scss';
import { Role } from '../../helpers/enums';
import { Text, getTheme } from "office-ui-fabric-react";
import { Sidebar, SidebarButton } from "@uifabric/experiments";
import { Link, NavLink, NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import { Persona } from '@fluentui/react-components';
import TopNavigation from './TopNavigation';
interface IAppLayoutProps
{

}

export const AppLayout : React.FunctionComponent<IAppLayoutProps> = (props: React.PropsWithChildren<IAppLayoutProps>) => {
  const navigate: NavigateFunction = useNavigate();
  const { user, logout, setCurrentGuild, isUserInRole } = useAuthenticationContext();
  const [showMobileWarning, setShowMobileWarning] = React.useState<boolean>(false)

  React.useEffect(() => {
    if(window.innerWidth <= 800)
      setShowMobileWarning(true)
  }, []);

  if(user == null) return <></>
  
  let userMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'changePassword',
        text: 'Skift adgangskode',
        iconProps: { iconName: 'PasswordField' },
        href:"/skift-adgangskode"
      },      
      {
        key: 'signOut',
        text: 'Log af',
        iconProps: { iconName: 'SignOut' },
        onClick: (ev) => {
          logout();
        }
      },
    ]
  };

  if(user.guilds.length > 1)
  {
    userMenuProps.items.splice(0, 0, {
      key: 'switchGuild',
      text: 'Skift vindmøllelaug',
      iconProps: { iconName: 'Switch' },
      onClick: (ev) => {
        setCurrentGuild(null);
        if(window.location.pathname != "/interessenter")
          navigate("/interessenter"); else window.location.reload();
      }
      });
  }

  if(isUserInRole(Role.Administrator))
    {
      userMenuProps.items.splice(0, 0, {
        key: 'companies',
        text: 'Selskaber',
        iconProps: { iconName: 'DataManagementSettings' },
        href:"/selskaber"
      });

      userMenuProps.items.splice(0, 0, {
        key: 'userAdministration',
        text: 'Brugeradministration',
        iconProps: { iconName: 'Contact' },
        href:"/brugeradministration"
      });
    }

  const RenderSideBarLink = (item: any) : React.ReactNode =>
  {
    return <NavLink to={item.path} className={({ isActive }) => (isActive ? "link-active" : "link")}>
              <SidebarButton
                key={item.key}
                text={item.name}
                iconProps={item.iconProps}
                role="menuitem"
                theme={getTheme()}
              />
            </NavLink>
  }

  return (
       <div className={styles.AppLayout}>               
        <div className={styles.AppHeader}>              
            <div className={styles.AppUserMenu}>            
              <CommandButton className={styles.userMenu} menuProps={userMenuProps}>
              <Persona
                textPosition="after"
                name={user?.name}
                secondaryText="Administrator"
              />
              </CommandButton>
            </div>
          </div>
        <div className={styles.AppContent}>
        {/* <div className={styles.AppSidebar}>            
            <Sidebar            
            collapsible={true}            
            theme={getTheme()}
            collapseButtonAriaLabel={"sitemap"}
            items={[
              {
                key: "dashboard",
                name: "Startside",
                path: "/",
                iconProps: { iconName: "Home" },
                onRender: (item) => { return RenderSideBarLink(item)}                
              },
              {
                key: "stakeholders",
                name: "Interessenter",
                path: "/interessenter",
                iconProps: { iconName: "People" },
                onRender: (item) => { return RenderSideBarLink(item)}                
              },
              {
                key: "sales",
                name: "Køb og salg",
                path: "/køb-salg",
                iconProps: { iconName: "Money" },
                onRender: (item) => { return RenderSideBarLink(item)}                
              },
              {
                key: "export",
                name: "Eksport",
                path: "/eksport",
                iconProps: { iconName: "ExcelLogo" },          
                onRender: (item) => { return RenderSideBarLink(item)}                
              },
              {
                key: "Konfiguration_Guild",
                name: "Firma",
                path: "/konfiguration/firma",
                iconProps: { iconName: "ContactCardSettings" },         
                onRender: (item) => { return RenderSideBarLink(item)}                           
              },
              {
                key: "Konfiguration_Banks",
                name: "Banker",
                path: "/konfiguration/banker",
                iconProps: { iconName: "Bank" },
                onRender: (item) => { return RenderSideBarLink(item)}              
              },
              {
                key: "Konfiguration_Nets",
                name: "Nets",
                path: "/konfiguration/nets",
                iconProps: { iconName: "PaymentCard" },
                onRender: (item) => { return RenderSideBarLink(item)}                                 
              },
              {
                key: "Konfiguration_TaxGroups",
                name: "Skattegrupper",
                path: "/konfiguration/skattegrupper",
                iconProps: { iconName: "EngineeringGroup" },
                onRender: (item) => { return RenderSideBarLink(item)}                                   
              },
              {
                key: "Konfiguration_Codes",
                name: "Ajour. koder",
                path: "/konfiguration/ajourkoder",
                iconProps: { iconName: "PasteAsCode" },
                onRender: (item) => { return RenderSideBarLink(item)}                                  
              },
              {
                key: "Konfiguration_Rates",
                name: "Satser",
                path: "/konfiguration/satser",
                iconProps: { iconName: "ClipboardListMirrored" },
                onRender: (item) => { return RenderSideBarLink(item)}                                    
              },
              {
                key: "Konfiguration_Turbines",
                name: "Vindmøller",
                path: "/konfiguration/vindmøller",
                iconProps: { iconName: "BuildQueue" },
                onRender: (item) => { return RenderSideBarLink(item)}                                   
              },
              {
                key: "Konfiguration_Users",
                name: "Brugere",
                path: "/konfiguration/brugere",
                iconProps: { iconName: "Contact" },
                onRender: (item) => { return RenderSideBarLink(item)}                                   
              },
              {
                key: "Konfiguration_Misc",
                name: "Diverse",
                path: "/konfiguration/diverse",
                iconProps: { iconName: "Settings" },
                onRender: (item) => { return RenderSideBarLink(item)}                                  
              },
            ]}/>
          
        </div> */}
        <div className={styles.AppMainPart} key={"AppLayout"}>
        <div className={styles.contentContainer}>            
            <Outlet />            
            </div>
        </div> 
        </div>
       </div>
      );
  }

  export default AppLayout;
