import { CreateAuthHeader } from "../helpers/authentication-helper";
import { IBank } from "../models";
import { IService } from "./ICommonServices";

export class BankServiceProvider implements IService<IBank>
{
    private controller = "api/banks";

    public async GetAll(): Promise<IBank[]> 
    {
        let result: IBank[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            return result;
        }

        return await response.json() as IBank[];
    }

    public async Get(id: number): Promise<IBank> 
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        if(!response.ok)
        {
            throw new Error("Bank not found");
        }

        return await response.json() as IBank;
    }

    public async Create(item: IBank) : Promise<string>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Update(item: IBank) : Promise<string>
    {
        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Delete(id: number) : Promise<string>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        return await response.text();
    }   
}
