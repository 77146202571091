  import React from 'react';
import { CommandBar, ICommandBarItemProps, SelectionMode, DetailsListLayoutMode, DetailsList, IColumn, Pivot, PivotItem, ShimmeredDetailsList, ConstrainMode, SearchBox, IDetailsListProps, IDetailsRowStyles, DetailsRow } from '@fluentui/react';
import styles from './Stakeholders.module.scss';
import { IStakeholder } from '../../models';
import StakeholderPanel from './StakeholderPanel';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import appstyles from './../../App.module.scss';
import { SelectTabData, SelectTabEvent, Tab, TabList, TabValue } from '@fluentui/react-components';
import GuildPicker from '../Dashboard/GuildPicker/GuildPicker';
import { IGuild } from '../../models/IGuild';

interface IStakeholdersProps
{

}

export const Stakeholders: React.FunctionComponent<IStakeholdersProps> = (props: React.PropsWithChildren<IStakeholdersProps>) => {
  //let _allStakeholders: IStakeholder[] = [];

  const { Services } = React.useContext(ApplicationContext);
  const [stakeholders, setStakeholders] = React.useState<IStakeholder[]>([]);  
  const [allStakeholders, setAllStakeholders] = React.useState<IStakeholder[]>([]);  
  const [selectedStakeholder, setSelectedStakeholder] = React.useState<IStakeholder | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [showStakeholderPanel, setShowStakeholderPanel] = React.useState<boolean>(false);
  
  const { user, guild, setCurrentGuild } = useAuthenticationContext();
  const [showGuildPicker, setShowGuildPicker] = React.useState<boolean>(false);
  React.useEffect(() => {
    if(user != null && user.guilds.length > 1 && guild == null)
    {
      setStakeholders([]);
      setShowGuildPicker(true);
    } 
  }, []);

  React.useEffect(() => {
    loadStakeholders();
  }, []);

  const loadStakeholders = async () => {
    setIsLoading(true);
    Services?.Stakeholder.GetStakeholders().then((stakeholders: IStakeholder[]) => {
      setAllStakeholders(stakeholders);
      setStakeholders(stakeholders);        
      setIsLoading(false);
    });        
  }
  
  const COMMANDBAR_COLUMNS = [
    {
      key: 'personID',
      name: 'Person ID',
      fieldName: 'personID',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    },
    {
      key: 'firstName',
      name: 'Fornavn',
      fieldName: 'firstName',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    },
    {
      key: 'lastName',
      name: 'Efternavn',
      fieldName: 'lastName',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    },
    {
      key: 'address',
      name: 'Gadevej',
      fieldName: 'address',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    },
    {
      key: 'houseNo',
      name: 'Hus nr.',
      fieldName: 'houseNo',
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    },
    {
      key: 'zipCode',
      name: 'Post nr.',
      fieldName: 'zipCode',
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    },
    {
      key: 'city',
      name: 'By',
      fieldName: 'city',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      //onColumnClick: this._onColumnClickDL.bind(this),
    }
  ] as IColumn[];

  const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return <div title="Klik for at åbne" style={{cursor: "pointer"}} onClick={() => {  setSelectedStakeholder(props.item); setShowStakeholderPanel(true); }}><DetailsRow {...props} styles={customStyles} /></div>
    }
    return null;
  }
  
  const CreateFarMenuItems = [
      {        
        key:"search",
        onRender:() => <SearchBox 
          placeholder="Søg efter ..." 
          className={appstyles.searchBox} 
          onSearch={(newValue) => { onSearch(newValue); } } 
          onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { onSearch(newValue); }}
          onClear={ev => { onSearch(""); }}
          />
      }
    ];

    const onSearch = (searchTerm: string | undefined) => {
      setStakeholders(searchTerm && searchTerm != "" ? filter(searchTerm) : allStakeholders);
    }

    const filter = (searchTerm: string) : IStakeholder[] => {
      return allStakeholders.filter(i => 
        (i.personID + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.firstName + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
        (i.lastName + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.address + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.houseNo + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.zipCode + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        (i.city + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
        (i.cpr + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
        (i.cvr + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
      );
    }

  const CommandBar_Assignments: ICommandBarItemProps[] = [
    /*{
        key: 'openAssignment',
        text: 'Åbn',
        iconProps: { iconName: 'OpenPaneMirrored' },
        onClick: () => { this.setState({ showStakeholderPanel: true}) },
        disabled: !this.state.selectedStakeholder
    }*/
    ];
    if(showGuildPicker) return <GuildPicker onSelect={(guild: IGuild | undefined) => { setCurrentGuild(guild as IGuild); setShowGuildPicker(false); loadStakeholders(); } } />

    return <div className={appstyles.contentContainer}>            
            <div className={appstyles.heading}><h3>Interessenter i {guild?.name} (i alt: {allStakeholders.length.toLocaleString('da')})</h3></div>
            <CommandBar className={appstyles.commandBar} items={CommandBar_Assignments} styles={{root: {paddingLeft:"0px"}}} farItems={CreateFarMenuItems} />              
            <div style={{margin:"20px"}}>                  
              <ShimmeredDetailsList
                  onShouldVirtualize={() => false }
                  items={stakeholders}
                  columns={COMMANDBAR_COLUMNS}
                  selectionMode={SelectionMode.none}
                  onRenderRow={_onRenderRow?.bind(this)}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  enableShimmer={isLoading}
                  setKey="set"
                />
            </div>
            
            {showStakeholderPanel && <StakeholderPanel selectedStakeholder={selectedStakeholder} onChanged={(stakeholder) => { const objIndex  = stakeholders.findIndex(x => x.personID == stakeholder.personID); stakeholders[objIndex] = stakeholder; setShowStakeholderPanel(false); }} onDismiss={() => { setShowStakeholderPanel(false);  } } />}            
        </div>
  }

export default Stakeholders;