import { IUser } from '../models/IUser';

export function CreateAuthHeader() : any {
    // return authorization header with basic auth credentials
    let cached = localStorage.getItem('uhrevind_token') + "";
    let user : IUser = JSON.parse(cached) as IUser;

    if (user && user.token) {        
        return { 'Authorization': 'Bearer ' + user.token, 'Content-Type': 'application/json' };
    } else {
        return {};
    }
}

export function CreateAuthHeaderAsFormData() : any {
    // return authorization header with basic auth credentials
    let cached = localStorage.getItem('uhrevind_token') + "";
    let user : IUser = JSON.parse(cached) as IUser;

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token, 'Content-Type': 'multipart/form-data' };
    } else {
        return {};
    }
}