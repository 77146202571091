import * as React from "react";

interface IChangePasswordProps
{
    
}

export const ChangePassword : React.FunctionComponent<IChangePasswordProps> = (props: React.PropsWithChildren<IChangePasswordProps>) => {

    return <div><h3>Skift adgangskode</h3></div>
}

export default ChangePassword;