import { CompoundButton, DetailsListLayoutMode, IColumn, ICommandBarItemProps, Panel, PanelType, SearchBox, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ListComponent from '../components/ListComponent';
import styles from '../App.module.scss';
import { ApplicationContext } from '../contexts/ApplicationContext';
import { FormMode } from '../helpers/enums';
import useObject from '../hooks/useObject';

interface IServiceLetterProps
{

}

export const ServiceLetter: React.FunctionComponent<IServiceLetterProps> = (props: React.PropsWithChildren<IServiceLetterProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { Services } = React.useContext(ApplicationContext);

    const [items, setItems] = React.useState<any[]>([]);  
    const [selected, setSelected] = React.useState<any | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue } = useObject<any>({} as any);
    
    const [formMode, setFormMode] = React.useState<FormMode>(FormMode.New);
    
    const COLUMNS = [ //ToDo
        {
          key: 'period',
          name: 'Periode',
          fieldName: 'period',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        },
        {
          key: 'amount',
          name: 'Elsalg pr. andel',
          fieldName: 'amount',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false
        },
        {
          key: 'interests',
          name: 'Renter pr. andel',
          fieldName: 'interests',
          minWidth: 150,
          maxWidth: 150,
          isResizable: false
        },
        {
            key: 'depreciation',
            name: 'Afskrivning pr. andel',
            fieldName: 'depreciation',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true
          },
          
        {
            key: 'carriedForwardDeficit',
            name: 'Overført underskud pr. andel',
            fieldName: 'carriedForwardDeficit',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true
          },
          {
              key: 'interestExpense',
              name: 'Renteudgift pr. andel',
              fieldName: 'interestExpense',
              minWidth: 150,
              maxWidth: 250,
              isResizable: true
            },            
        {
            key: 'extra',
            name: 'Ekstra pr. andel (1)',
            fieldName: 'extra1',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true
          },
          {
            key: 'extra',
            name: 'Ekstra pr. andel (2)',
            fieldName: 'extra2',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true
          },
          {
            key: 'extra',
            name: 'Ekstra pr. andel (3)',
            fieldName: 'extra3',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true
          }
      ] as IColumn[];

      React.useEffect(() => {    
        Fetch();
      }, []);

      React.useEffect(() => {
        setValue(selected as any);
      }, [selected]);

const Fetch = async () => {
    Services?.ServiceLetter.GetAll().then((result: any[]) => {          
        setIsLoading(true);
        setItems(result);
        setIsLoading(false);
      }); 
}
const Reset = () => {
    setSelected(undefined);
    setShowPanel(false);
}

    const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Nyt servicebrev',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setFormMode(FormMode.New); setShowPanel(true);
             },
            disabled: selected != undefined
        }
        ];

      const CreateFarMenuItems = [
        {        
          key:"search",
          onRender:() => <SearchBox 
            placeholder="Søg efter ..." 
            className={styles.searchBox} 
            onSearch={(newValue) => { /*this.onSearch(newValue);*/ } } 
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { /*onSearch(newValue);*/ }}
            onClear={ev => { /*onSearch("");*/ }}
            />
        }
      ];
      
    return <div>
                <ListComponent commands={Commands} farItems={CreateFarMenuItems} title={'Servicebreve'}>
                <ShimmeredDetailsList
                    onShouldVirtualize={() => false }
                    items={items}
                    columns={COLUMNS}
                    selectionMode={SelectionMode.none}
                    //onRenderRow={_onRenderRow?.bind(this)}           
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    enableShimmer={isLoading}
                    setKey="set"
                    />
                </ListComponent>
                {showPanel && 
                    <Panel
                    headerText={formMode === FormMode.New ? "Opret" : "Rediger"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) Reset(); }}              
                    closeButtonAriaLabel="Luk panel"
                    // onRenderFooterContent={() => {
                    // return <>
                    //     <PrimaryButton appearance="primary" icon={<Save20Regular />} onClick={() => AddOrUpdate()} label={formMode === FormMode.New ? "Opret" : "Gem ændringer"} />
                    //     <PrimaryButton appearance="normal" icon={<CalendarCancel20Regular />}  onClick={() => Reset() } label="Annuller" />                  
                    //     {formMode === FormMode.Edit && <PrimaryButton appearance="delete" icon={<Delete20Regular />}  onClick={() => Delete()} label="Slet" />}
                    // </>
                    // }}
                    // type={PanelType.custom} customWidth='1000px' >
                    type={PanelType.medium}>
                        ToDo: Angiv periode eller kopier fra tidligere, som ved udbetalinger.
                        </Panel>
                    }
                {/* <h5>Generelt</h5>   */}
                {/* <div style={{display:"grid", gap:"5px",gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))"}}>
                    <CompoundButton text='Udbetaling' secondaryText='Eksport af oplysninger til udbetaling'/>
                    <CompoundButton text='Oplysninger til servicebreve' secondaryText='Eksport af oplysninger til udsendelse af servicebreve'/>
                </div>           */}
           </div>
}

export default ServiceLetter;