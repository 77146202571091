//import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import React from 'react';
import { IBank, IBankInfo, IShare, IStakeholder } from '../../models';
import commonStyles from '../../styles/Common.module.scss';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import useObject from '../../hooks/useObject';
import { DefaultButton, DetailsList, DetailsListLayoutMode, Dropdown, IColumn, IDropdownOption, Label, Link, Panel, PanelType, Pivot, PivotItem, PrimaryButton, ScrollablePane, SelectionMode, Spinner, SpinnerSize, TextField, Toggle } from '@fluentui/react';
import Section from './Section';
import BanksPanel from '../Settings/Banks/BanksPanel';
import { FormMode } from '../../helpers/enums';

interface IStakeholderPanelProps
{
    selectedStakeholder: IStakeholder | undefined;
    onChanged(stakeholder: IStakeholder): void;
    onDismiss(): void;
}

export const StakeholderPanel : React.FunctionComponent<IStakeholderPanelProps> = (props: React.PropsWithChildren<IStakeholderPanelProps>) => {
    const { Services } = React.useContext(ApplicationContext);    
    const { value: entity, updateValue: updateEntity } = useObject<IStakeholder>(props.selectedStakeholder ? props.selectedStakeholder : {} as IStakeholder);
    const [showSharesList, setShowSharesList] = React.useState<boolean>(false);
    const [showAddBankRelationPanel, setShowAddBankRelationPanel] = React.useState<boolean>(false);
    const [showAddBankPanel, setShowAddBankPanel] = React.useState<boolean>(false);
    const [banks, setBanks] = React.useState<IBank[]>([]);  
    React.useEffect(() => {
        Services?.Stakeholder.GetStakeholder(entity.personID).then((stakeholder: IStakeholder) => {
            updateEntity(stakeholder);
            setShowSharesList(stakeholder?.shares.length < 1000);
        });
    }, []);

    React.useEffect(() => {
        if(showAddBankRelationPanel && banks.length == 0)
        {            
            fetchBanks();
        }
    }, [showAddBankRelationPanel]);

    const fetchBanks =() => {
        Services?.Bank.GetAll().then((result: IBank[]) => {                          
            setBanks(result);
        });
    }

    const validateForm = () =>
    {
        return true;
    }

    const Update = async () =>
    {
        let result = await Services?.Stakeholder.UpdateStakeholder(entity);

        if(result === true)
        {
            props.onChanged(entity);
        } else
        {
            alert(result);
        }
    }

    //TODO: Get from backend, table: Skattegruppe
    const taxGroups: any[] = [{ key: 1, text: "fri" }, { key: 2, text: "Skematisk regel" }, { key: 3, text: "Erhvervsmæssig opgørelse (Anparts regel)"}, { key: 4, text: "Aktieselskaber/Anpartsselskaber"} ];

    const COLUMNS_BANK = [
        {
          key: 'regNo',
          name: 'Regnr.',
          fieldName: 'regNo',
          minWidth: 50,
          maxWidth: 50,
          isResizable: false,
          onRender: (item: IBankInfo) => {
            return <span>{item.bank.regNo}</span>
          }
        },
        {
            key: 'name',
            name: 'Banknavn',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: IBankInfo) => {
              return <span>{item.bank.name}</span>
            }
          },
        {
          key: 'accountNo',
          name: 'Kontonummer',
          fieldName: 'accountNo',
          minWidth: 125,
          maxWidth: 125,
          isResizable: true,
        },
        {
            key: 'current',
            name: 'Aktiv',
            fieldName: 'current',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: IBankInfo) => {
              return <span>{item.current ? "Ja" : "Nej"}</span>
            }
          },
      ] as IColumn[];
    
      const COLUMNS_SHARES = [
        {
          key: 'shareId',
          name: 'Andelsnummer',
          fieldName: 'shareId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false,
        },
        {
            key: 'transport',
            name: 'Transport',
            fieldName: 'transport',
            minWidth: 100,
            maxWidth: 100,
            isResizable: false,
            onRender: (item: IShare) => {
              return <span>{item.transport ? "Ja" : "Nej"}</span>
            }
          },
        {
          key: 'mortgage',
          name: 'Håndpant',
          fieldName: 'mortgage',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false,
          onRender: (item: IShare) => {
            return <span>{item.mortgage ? "Ja" : "Nej"}</span>
          }
        }
      ] as IColumn[];

    return (
        <Panel
            headerText={`Stamkort: ${props.selectedStakeholder?.firstName} ${props.selectedStakeholder?.lastName}`}
            isOpen={true}                           
            isLightDismiss={false}                     
            isBlocking={true}
            onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) props.onDismiss() }}              
            closeButtonAriaLabel="Luk panel"
            onRenderFooterContent={() => {
            return <>
                <PrimaryButton styles={{root:{marginRight:"10px"}}} onClick={() => Update()} text="Gem ændringer" />
                <DefaultButton onClick={() => props.onDismiss()} text="Annuller" />                  
            </>
            }}
            // type={PanelType.custom}
            // customWidth='1200px'
            type={PanelType.extraLarge}
            >
                <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px", marginTop:"10px"}}>
                    <Section title='Personoplysninger' icon='FabricUserFolder'>
                        <TextField label={"Person ID"} value={entity.personID + ""} disabled={true} />                    
                        <TextField label={"CPR nr."} value={entity.cpr} onChange={(ev, newValue: string | undefined) => { console.log(newValue); updateEntity({cpr: newValue}) }} />                        
                        <TextField label={"CVR nr."} value={entity.cvr} onChange={(ev, newValue: string | undefined) => { updateEntity({cvr: newValue}) }} />
                        <TextField label={"Titel"} value={entity.title} onChange={(ev, newValue: string | undefined) => { updateEntity({title: newValue}) }} />
                        <TextField label={"Fornavn"} value={entity.firstName} onChange={(ev, newValue: string | undefined) => { updateEntity({firstName: newValue}) }} />
                        <TextField label={"Efternavn"} value={entity.lastName} onChange={(ev, newValue: string | undefined) => { updateEntity({lastName: newValue}) }} />
                        <TextField label={"Attention"} value={entity.attention} onChange={(ev, newValue: string | undefined) => { updateEntity({attention: newValue}) }} />
                        <TextField label={"Gadevej"} value={entity.address} onChange={(ev, newValue: string | undefined) => { updateEntity({address: newValue}) }} />
                        <TextField label={"Hus nr."} value={entity.houseNo} onChange={(ev, newValue: string | undefined) => { updateEntity({houseNo: newValue}) }} />
                        <TextField label={"Post nr."} value={entity.zipCode} onChange={(ev, newValue: string | undefined) => { updateEntity({zipCode: newValue}) }} />
                        <TextField label={"By"} value={entity.city} onChange={(ev, newValue: string | undefined) => { updateEntity({city: newValue}) }} />                        
                    </Section>
                    <Section title={`Andele (${entity.shares?.length} stk.)`} icon='TextDocumentShared'>
                        <Label>- heraf pant: {entity.shares?.filter(x => x.mortgage).length} stk.</Label>
                        <Label>- heraf transport: {entity.shares?.filter(x => x.transport).length} stk.</Label>
                       {entity.shares?.length > 1000 && <Toggle inlineLabel label="Vis/skjul liste over andele" onChange={(ev, checked) => { setShowSharesList(checked as boolean); }} />}
                    {showSharesList && entity.shares ?
                        <DetailsList
                            styles={{root: {maxHeight:"50vh"}}}
                            setKey='detailsListShares'
                            items={entity.shares}
                            onShouldVirtualize={ () => false }
                            columns={COLUMNS_SHARES}
                            selectionMode={SelectionMode.none}                   
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                        /> : <Spinner size={SpinnerSize.large} />
                    }            
                    </Section>
                    <Section title='Bankoplysninger' icon='Bank'>
                        {entity.bankInfos ? 
                        <><DetailsList
                            items={entity.bankInfos}
                            setKey='detailsListBankInfos'
                            columns={COLUMNS_BANK}
                            selectionMode={SelectionMode.none}                            
                            layoutMode={DetailsListLayoutMode.justified} />
                            <PrimaryButton styles={{root:{marginTop:"10px"}}} text="Tilføj bankrelation" onClick={() => { setShowAddBankRelationPanel(true); }} />
                            {showAddBankRelationPanel &&                             
                                <Panel isOpen={true} isLightDismiss={false} isBlocking={true} headerText='Tilføj ny bankrelation' type={PanelType.medium} onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) setShowAddBankRelationPanel(false) }}  onRenderFooterContent={() => {
                                    return <>
                                        <PrimaryButton styles={{root:{marginRight:"10px"}}} onClick={() => Update()} text="Opret" />
                                        <DefaultButton onClick={() => setShowAddBankRelationPanel(false) } text="Annuller" />                  
                                    </>
                                    }}>
                                    <Dropdown label='Vælg bank' options={banks.map((val, i, a) => { return { key: val.regNo, text: `${val.regNo} - ${val.name}` } as IDropdownOption })} />
                                    <div>Findes banken ikke? Opret ny <Link onClick={() => { setShowAddBankPanel(true); }}>her</Link></div>
                                    {showAddBankPanel && <BanksPanel formMode={FormMode.New} selected={undefined} onDismiss={(update: boolean) => { if(update) fetchBanks(); setShowAddBankPanel(false); }}  />}
                                    <TextField label="Kontonummer" />
                                </Panel>
                            }
                            </> : <Spinner size={SpinnerSize.large} />}
                    </Section>
                    <Section title='Kontaktoplysninger' icon='ContactCard'>
                        <TextField label={"Telefon"} type="tel" value={entity.phone} onChange={(ev, newValue: string | undefined) => { updateEntity({phone: newValue}) }} />
                        <TextField label={"Mobiltelefon"} type="tel" value={entity.cellphone} onChange={(ev, newValue: string | undefined) => { updateEntity({cellphone: newValue}) }} />
                        <TextField label={"E-mailadresse"} type={"email"} value={entity.email} onChange={(ev, newValue: string | undefined) => { updateEntity({email: newValue}) }} />                        
                        <Toggle label={"Brug e-mailadresse"} checked={entity.useEmail} onChange={(ev, checked?: boolean | undefined) => { updateEntity({useEmail: checked}) }} />                        
                    </Section>
                    <Section title='Øvrige oplysninger' icon='ContactInfo'>
                        <Dropdown label={"Skattegruppe"} selectedKey={entity.taxGroupId} onChange={(ev, option: IDropdownOption | undefined, index: number | undefined) => { updateEntity({ taxGroupId: option?.key as number }); } } options={taxGroups} disabled />                        
                        <TextField label={"Ægtefælles CPR nr."} value={entity.spouseCPR} onChange={(ev, newValue: string | undefined) => { updateEntity({spouseCPR: newValue}) }} />
                        <TextField label={"Postsorteringskode"} value={entity.postSortCode} onChange={(ev, newValue: string | undefined) => { updateEntity({postSortCode: newValue}) }} />                        
                    </Section>                    
                    <Section title='Notater' icon='EditNote'>
                        <TextField multiline rows={10} value={entity.note} onChange={(ev, newValue: string | undefined) => { updateEntity({note: newValue}) }} />
                    </Section>
                </div>                        
                <div style={{marginTop:"10px"}}>
                    <Section title='Filer' icon='TextDocumentShared'>
                            <i>Kommer senere!</i>
                    </Section>                
                </div>
        </Panel>
    );
}

export default StakeholderPanel;