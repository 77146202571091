import { IBank, IPayout, ISales, IServiceLetter } from "../models";
import { BankServiceProvider } from "./BankService";
import { PayoutServiceProvider } from "./PayoutService";
import { SalesServiceProvider } from "./SalesServiceProvider";
import { ServiceLetterServiceProvider } from "./ServiceLetterServiceProvider";
import { IStakeholderService, StakeholderServiceProvider } from "./StakeholderService";
import { IMasterDataService, MasterDataService } from "./UserService";

export interface ICommonServices {
    Master: IMasterDataService;
    Stakeholder: IStakeholderService;
    Bank: IService<IBank>;
    Payout: IService<IPayout>;
    ServiceLetter: IService<IServiceLetter>;
    Sales: IService<ISales>;
}

export class CommonServices implements ICommonServices {
    public Master: IMasterDataService;
    public Stakeholder: IStakeholderService;
    public Bank: IService<IBank>;
    public Payout: IService<IPayout>;
    public ServiceLetter: IService<IServiceLetter>;
    public Sales: IService<ISales>;
    
    constructor() {
        this.Master = new MasterDataService();
        this.Stakeholder = new StakeholderServiceProvider();
        this.Bank = new BankServiceProvider();
        this.Payout = new PayoutServiceProvider();
        this.ServiceLetter = new ServiceLetterServiceProvider();
        this.Sales = new SalesServiceProvider();
    }
}

export interface IService<T>
{
    GetAll() : Promise<T[]>;
    Get(id: number) : Promise<T>;
    Create(stakeholder: T) : Promise<string>;
    Update(stakeholder: T) : Promise<string>;
    Delete(id: number) : Promise<string>;
}
