import React, { ReactNode } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { IRole, IUser } from '../models';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { IGuild } from '../models/IGuild';
import { useApplicationContext } from '../hooks/useApplicationContext';

export interface IAuthenticationProviderProps
{
}

export const AuthenticationProvider : React.FunctionComponent<IAuthenticationProviderProps> = (props: React.PropsWithChildren<IAuthenticationProviderProps>) => {    
    const { Services } = useApplicationContext();
    const [user, setUser] = React.useState<IUser|null>(null);
    const [guild, setGuild] = React.useState<IGuild|null>(null);
    const { getItem, setItem, removeItem } = useLocalStorage();
    const [isCheckingAuth, setIsCheckingAuth] = React.useState(true);
    
    React.useEffect(() => {
        const user = getItem("uhrevind_token");
        if (user) {
          setUser(JSON.parse(user));
        }

        const guild = getItem("uhrevind_currentGuild");
        if (guild) {
          setGuild(JSON.parse(guild));
        }

        setIsCheckingAuth(false);
      }, []);

      React.useEffect(() => {
        if(guild != null)
        {
            Services?.Master.SetGuild(guild);
        }
        
      }, [guild]);

    const login = (user) => {
        setUser(user);
        setItem('uhrevind_token', JSON.stringify(user));
    };

    const setCurrentGuild = (guild) => {
        setItem('uhrevind_currentGuild', JSON.stringify(guild));
        setGuild(guild);
    }

    const logout = () => {
        setUser(null);
        removeItem('uhrevind_token');
    };

    const isUserInRole = (role: string) : boolean =>
    {
        return user?.roles.some(i => i.role.includes(role)) as boolean;
    }

    const value = {
        user,
        login,
        logout,
        isCheckingAuth,
        guild,
        setCurrentGuild,
        isUserInRole
    };

    return (
        <>
            <AuthenticationContext.Provider value={value}>
                {props.children}
            </AuthenticationContext.Provider>
        </>
    );
}

export default AuthenticationProvider;