import * as React from 'react';
import { Icon, Text } from 'office-ui-fabric-react';

interface ISectionProps
{
    title: string;
    icon: string;
}
export const Section: React.FunctionComponent<ISectionProps> = (props: React.PropsWithChildren<ISectionProps>) => {
    return (<div style={{backgroundColor:"#fff", borderRadius:"4px", padding:"10px", boxShadow:"rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.14) 0px 1px 2px"}}>
                <div style={{display:"grid", gridTemplateColumns:"90% 10%"}}>
                    <Text variant='large'>{props.title}</Text>
                    <Icon iconName={props.icon} style={{textAlign:"right", fontSize:"24px"}} title={props.title}/>
                </div>
                <div style={{paddingTop:"10px"}}>
                    {props.children}
                </div>
            </div>
    );
};

export default Section;