import React from 'react';

interface ICodesProps
{

}

export const Codes: React.FunctionComponent<ICodesProps> = (props: React.PropsWithChildren<ICodesProps>) => {
    return <div><h3>Ajour. koder</h3></div>
}

export default Codes;