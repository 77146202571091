import { CompoundButton } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface IProductionOverviewProps
{

}

export const ProductionOverview: React.FunctionComponent<IProductionOverviewProps> = (props: React.PropsWithChildren<IProductionOverviewProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    
    return <div>
                <h3>Produktionsoversigt</h3>
                {/* <h5>Generelt</h5>   */}
                {/* <div style={{display:"grid", gap:"5px",gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))"}}>
                    <CompoundButton text='Udbetaling' secondaryText='Eksport af oplysninger til udbetaling'/>
                    <CompoundButton text='Oplysninger til servicebreve' secondaryText='Eksport af oplysninger til udsendelse af servicebreve'/>
                </div>           */}
           </div>
}

export default ProductionOverview;