import { DatePicker, DefaultButton, DetailsListLayoutMode, Dropdown, DropdownMenuItemType, IColumn, IComboBoxOption, ICommandBarItemProps, IDropdownOption, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, VirtualizedComboBox } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ApplicationContext } from '../contexts/ApplicationContext';
import useObject from '../hooks/useObject';
import { FormMode } from '../helpers/enums';
import styles from '../App.module.scss';
import ListComponent from '../components/ListComponent';
import StakeholderSelection from '../components/Stakeholders/StakeholderSelection';
import { format } from "date-fns";
import { DatePicker_onFormatDate } from '../helpers/DateHelper';
import { ISales, IShare, IStakeholder } from '../models';

interface ISalesProps
{

}

// interface ISalesDTO
// {
//   buyDate: Date;
//   companyId: number;
//   shareId: number;
//   created: Date;
//   buyerId: number;
//   buyerBankRelationshipId: number;
//   sellerId: number;
//   sellerBankRelationshipId: number;
// }

export const Sales: React.FunctionComponent<ISalesProps> = (props: React.PropsWithChildren<ISalesProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { Services } = React.useContext(ApplicationContext);

    const [items, setItems] = React.useState<ISales[]>([]);  
    const [selected, setSelected] = React.useState<ISales | undefined>(undefined);
    const [shares, setShares] = React.useState<IShare[]>([]);  
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue } = useObject<ISales>({} as ISales);
    
    const [selectedKeys, setSelectedKeys] = React.useState<number[]>([]);

    const [seller, setSeller] = React.useState<IStakeholder>();
    const [buyer, setBuyer] = React.useState<IStakeholder>();
    
    // const { value: salesCreationObject, updateValue: updateSalesCreationObject } = useObject<ISales>({} as ISalesCreationDTO);

    const onChange = (event: any, item: IDropdownOption): void => {
        if (item) {
          //TODO: If "Vælg alle", vælg alle
            const res = item.selected ? [...selectedKeys, item.key as number] : selectedKeys.filter(key => key !== item.key);
            
          setSelectedKeys(res);
          updateEntity({shareIds: res});
        }
      };

    const COLUMNS = [ //ToDo
        {
          key: 'buyDate',
          name: 'Dato for køb/salg',
          fieldName: 'buyDate',
          minWidth: 125,
          maxWidth: 125,
          isResizable: false,
          onRender: (item: ISales) => { return <span>{format(item.buyDate, "dd-MM-yyyy")}</span> }
        },
        {
          key: 'shareId',
          name: 'Andel',
          fieldName: 'shareId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: true
        },
        {
          key: 'sellerId',
          name: 'Sælger',
          fieldName: 'sellerId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        },
        {
          key: 'buyerId',
          name: 'Køber',
          fieldName: 'buyerId',
          minWidth: 100,
          maxWidth: 100,
          isResizable: false
        }        
      ] as IColumn[];

      React.useEffect(() => {    
        Fetch();
      }, []);

      React.useEffect(() => {    
        console.log(entity)
      }, [entity]);

      React.useEffect(() => {
        setValue(selected as any);
      }, [selected]);

const Fetch = async () => {
    Services?.Sales.GetAll().then((result: any[]) => {          
        setIsLoading(true);
        setItems(result);
        setIsLoading(false);
      }); 
}
const Reset = () => {
    setSelected(undefined);
    setShowPanel(false);
}

    const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Nyt salg',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setShowPanel(true);
             },
            disabled: selected != undefined
        }
        ];

      const CreateFarMenuItems = [
        {        
          key:"search",
          onRender:() => <SearchBox 
            placeholder="Søg efter ..." 
            className={styles.searchBox} 
            onSearch={(newValue) => { /*this.onSearch(newValue);*/ } } 
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { /*onSearch(newValue);*/ }}
            onClear={ev => { /*onSearch("");*/ }}
            />
        }
      ];

    const Create = async() => {
      if(window.confirm(`Er du sikker på at du vil gennemføre salget af ${selectedKeys.length} andele?`))
      {
        const result = await Services?.Sales.Create(entity);

        if(result == "OK")
          {
            Fetch();
            setShowPanel(false);
            updateEntity({ sellerId: undefined, buyerId: undefined, buyerBankRelationshipId: undefined, buyDate : undefined, shareIds: undefined});
          } else
          {
            alert(result);
          }
      }
    }
      
    return <div>
                <ListComponent commands={Commands} farItems={CreateFarMenuItems} title={'Køb & salg'}>
                <ShimmeredDetailsList
                    onShouldVirtualize={() => false }
                    items={items}
                    columns={COLUMNS}
                    selectionMode={SelectionMode.none}
                    //onRenderRow={_onRenderRow?.bind(this)}           
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    enableShimmer={isLoading}
                    setKey="set"
                    />
                </ListComponent>
                {showPanel && 
                    <Panel
                    headerText={"Opret nyt salg"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) Reset(); }}              
                    closeButtonAriaLabel="Luk panel"
                    onRenderFooterContent={() => {
                      return <PrimaryButton text="Gem og afslut salg" onClick={() => { Create() }} />
                    }}
                    // return <>
                    //     <PrimaryButton appearance="primary" icon={<Save20Regular />} onClick={() => AddOrUpdate()} label={formMode === FormMode.New ? "Opret" : "Gem ændringer"} />
                    //     <PrimaryButton appearance="normal" icon={<CalendarCancel20Regular />}  onClick={() => Reset() } label="Annuller" />                  
                    //     {formMode === FormMode.Edit && <PrimaryButton appearance="delete" icon={<Delete20Regular />}  onClick={() => Delete()} label="Slet" />}
                    // </>
                    // }}
                    // type={PanelType.custom} customWidth='1000px' >
                    type={PanelType.medium}>                        
                        <StakeholderSelection disabled={false} label="Sælger" OnStakeholderSelected={(selection: IStakeholder) => { setSeller(selection); setSelectedKeys([]); updateEntity({sellerId: selection.personID});  if(selection.shares) setShares(selection.shares); }} />                                                
                        <VirtualizedComboBox
                          disabled={entity.sellerId === undefined}
                            label="Andele"
                            useComboBoxAsMenuWidth
                            placeholder='Vælg andele'
                            // selectedKeys={selectedKeys}
                            // eslint-disable-next-line react/jsx-no-bind
                             onChange={(ev, option,i, val) => { onChange(ev, option as IComboBoxOption) }}
                            multiSelect
                            //options={[{ key: 'Header', text: 'Vælg alle', itemType: DropdownMenuItemType.SelectAll },{key: 1, text:"1"},{key: 2, text:"2"},{key: 3, text:"3"},{key: 4, text:"4"}]}
                            options={shares.map((val, i, a) => { return { key: val.shareId, text: `${val.shareId}`, disabled: val.transport || val.mortgage, title: val.transport ? "Der er transport i andelen og kan derfor ikke sælges!" : val.mortgage ? "Der er håndpant i andelen og kan derfor ikke sælges!" : "" } as IDropdownOption })}
                            />                            
                            {entity.shareIds?.length > 0 && <MessageBar messageBarType={MessageBarType.info}>Der er markeret i alt <b>{selectedKeys.length} andele</b></MessageBar>}
                        <StakeholderSelection disabled={entity.sellerId === undefined} label="Køber" OnStakeholderSelected={(selection: IStakeholder) => { setBuyer(selection); updateEntity({buyerId: selection.personID, buyerBankRelationshipId: selection.bankInfos.length == 1 ? selection.bankInfos[0].id : undefined}); }} />
                        <Dropdown disabled={buyer === undefined} label="Købers bank" options={buyer === undefined ? [] : buyer.bankInfos.map((val, i, a) => { return { key: val.id, text: `${val.bank.regNo} - ${val.accountNo}` } as IDropdownOption })} onChange={(ev, option) => { updateEntity({buyerBankRelationshipId: option?.key as number}); }} selectedKey={entity.buyerBankRelationshipId} />
                        <DatePicker disabled={entity.sellerId === undefined} label="Dato for salg" formatDate={DatePicker_onFormatDate} value={entity.buyDate} onSelectDate={(date) => { updateEntity({buyDate: date as Date}); }} />            
                        
                        </Panel>
                    }
                {/* <h5>Generelt</h5>   */}
                {/* <div style={{display:"grid", gap:"5px",gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))"}}>
                    <CompoundButton text='Udbetaling' secondaryText='Eksport af oplysninger til udbetaling'/>
                    <CompoundButton text='Oplysninger til servicebreve' secondaryText='Eksport af oplysninger til udsendelse af servicebreve'/>
                </div>           */}
           </div>
}

export default Sales;