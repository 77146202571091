import React from 'react';

interface IMiscProps
{

}

export const Misc: React.FunctionComponent<IMiscProps> = (props: React.PropsWithChildren<IMiscProps>) => {
    return <div><h3>Diverse</h3></div>
}

export default Misc;