import { CommandBar, ICommandBarItemProps, Panel, PanelType } from '@fluentui/react';
import React from 'react';
import appstyles from '../App.module.scss';
//import PrimaryButton from './FluentUI9/PrimaryButton';
//import { Save20Regular, CalendarCancel20Regular, Delete20Regular } from '@fluentui/react-icons';

interface IListComponentProps
{
    title: string;
    commands: ICommandBarItemProps[];
    farItems: ICommandBarItemProps[];
}

export const ListComponent: React.FunctionComponent<IListComponentProps> = (props: React.PropsWithChildren<IListComponentProps>) => {
    return <><div className={appstyles.heading}><h3>{props.title}</h3></div>
            <CommandBar className={appstyles.commandBar} items={props.commands} styles={{root: {paddingLeft:"0px"}}} farItems={props.farItems} />
            <div style={{margin:"20px"}}>
                {props.children}
            </div>     
            </>
}

export default ListComponent;

// interface IListComponentEditablePanelProps
// {
//     selected: any | undefined;
//     onChanged(item: any): void;
//     onDismiss(): void;
// }

// export const ListComponentEditablePanel: React.FunctionComponent<IListComponentEditablePanelProps> = (props: React.PropsWithChildren<IListComponentEditablePanelProps>) => {
//     return <Panel
//     headerText={"Rediger interessent"}
//     isOpen={true}                           
//     isLightDismiss={false}
//     isBlocking={true}
//     onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) props.onDismiss() }}              
//     closeButtonAriaLabel="Luk panel"
//     onRenderFooterContent={() => {
//     return <>
//         <PrimaryButton appearance="primary" icon={<Save20Regular />} onClick={() => props.Update()} label="Gem ændringer" />
//         <PrimaryButton appearance="normal" icon={<CalendarCancel20Regular />}  onClick={() => props.onDismiss()} label="Annuller" />                                
//         <PrimaryButton appearance="delete" icon={<Delete20Regular />}  onClick={() => props.Delete()} label="Slet" />                  
//     </>
//     }}
//     type={PanelType.medium}>
//             {props.children}
//     </Panel>
// }