import React from 'react';

interface INetsProps
{

}

export const Nets: React.FunctionComponent<INetsProps> = (props: React.PropsWithChildren<INetsProps>) => {
    return <div><h3>Nets</h3></div>
}

export default Nets;